import { render, staticRenderFns } from "./EditDeleteShareSpaceDropDown.vue?vue&type=template&id=4300c09d&scoped=true&"
import script from "./EditDeleteShareSpaceDropDown.vue?vue&type=script&lang=js&"
export * from "./EditDeleteShareSpaceDropDown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4300c09d",
  null
  
)

export default component.exports