<template>
  <div>
    <ul class="mt-2 mb-2 leading-8">
      <li v-for="board in sharedSpaceBoards"  :key="board.id" class="inline">
        <a href="#" class="relative mr-2 mt-1.5 mb-1.5 inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5">
          <div class="absolute flex-shrink-0 flex items-center justify-center">
            <span class="h-1.5 w-1.5 rounded-full bg-indigo-500" aria-hidden="true"></span>
          </div>
          <div class="ml-3.5 mr-2 text-sm text-gray-900 truncate">{{ board.title }}</div>
          <div class="flex-shrink-1 flex items-center justify-end" @click="$emit('removeBoard', board.id)">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Boards',
  props: {
    sharedSpaceBoards: {
      type: Array
    }
  }
}
</script>

<style scoped>

</style>
