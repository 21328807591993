<template>
  <div
      @mouseover="mouseOver(true)"
      @mouseleave="mouseLeave(false)"
      v-click-outside="closeDropdown"
      class="w-full grid grid-cols-10 pt-3">
    <div class="col-span-9">
      <div
          v-if="editableItem !== String(space.id)"
          class="h-auto truncate border-0 overflow-y-hidden text-sm text-gray-900 outline-none focus:ring-0 p-0">
        {{ spaceValue }}
      </div>
      <input
          v-if="editableItem === String(space.id)"
          autofocus="autofocus"
          ref="editableInput"
          class="w-full h-auto border-0 overflow-y-hidden text-sm text-gray-900 outline-none focus:ring-0 p-0"
          @change="$emit('handleChangeShareSpace', space.id)" v-model="spaceValue" />
      <div>
        <Boards :sharedSpaceBoards="sharedSpaceBoards" @removeBoard="removeBoard"/>
      </div>
    </div>
    <div class="ml-4 col-span-1 flex justify-end share_space_area">
      <EditDeleteShareSpaceDropDown
          v-if="showEditable || (selectedItem === String(space.id) || isOpenBoardsOption)"
          @openDeleteShareSpaceModal="$emit('openDeleteShareSpaceModal', space)"
          @handleOpenAddBoardsDropDown="handleOpenAddBoardsDropDown"
          @handleEdit="handleEdit"
          @handleSelectItem="handleSelectItem"
          :space="space"
          :boards="boards"
          :sharedSpaceBoards="sharedSpaceBoards"
          @handleRemoveBoard="removeBoard"
          @handleAddSpaceBoard="addSpaceBoard"
      />
    </div>
  </div>
</template>

<script>
import EditDeleteShareSpaceDropDown from '@/components/Main/ShareSpace/EditDeleteShareSpaceDropDown/EditDeleteShareSpaceDropDown';
import ClickOutside from 'vue-click-outside';
import Boards from '@/components/Main/ShareSpace/ShareSpaces/Boards/Boards';
import { mapActions } from 'vuex';

export default {
  name: 'SharedSpaceItem',
  components: {
    Boards,
    EditDeleteShareSpaceDropDown
  },
  directives: {
    ClickOutside
  },
  props: {
    space: {
      type: Object,
      'default': Object
    },
    boards: {
      type: Array,
      'default': Array
    },
    editableItem: String,
    editable: Boolean,
    selectedItem: String,
    pageUpdateAction: Boolean
  },
  data() {
    return {
      showEditable: false,
      isOpenEditAction: false,
      isOpenBoardsOption: false,
      spaceValue: '',
      sharedSpaceBoards: [],
      awaitingSearch: false
    }
  },
  mounted() {
    this.sharedSpaceBoards = this.space.boards;
    this.spaceValue = this.space.title;
  },
  methods: {
    ...mapActions({
      handleRemoveBoard: 'removeSpaceBoard',
      handleAddSpaceBoard: 'addSpaceBoard'
    }),
    closeDropdown() {
      this.isOpenBoardsOption = false;
    },
    mouseOver(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    mouseLeave(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    handleSelectItem(selectedItem) {
      this.$emit('handleSetSelectedItem', selectedItem);
      this.isOpenEditAction = true;
    },
    handleOpenAddBoardsDropDown(isOpen) {
      this.isOpenBoardsOption = isOpen;
    },
    addSpaceBoard(item) {
      this.sharedSpaceBoards.push(item);
      this.pageUpdateAction && this.handleAddSpaceBoard({ spaceId: this.space.id, id: item.id });
    },
    removeBoard(boardId) {
      this.sharedSpaceBoards = this.sharedSpaceBoards.filter(board => board.id !== boardId);
      this.handleRemoveBoard({ spaceId: this.space.id, id: boardId });
    },
    handleEdit(space) {
      process.nextTick(() => this.$refs.editableInput.focus());
      this.$emit('handleEdit', space);
    }
  }
}
</script>

<style scoped>
[contenteditable] {
  outline: 0 solid transparent;
}

.share_space_area:before {
  position: absolute;
  content: '';
  background: linear-gradient(to left, rgba(255, 255, 255, 1) 48%, rgba(255, 255, 255, 0) 80%);
  width: 170px;
  height: 24px;
  top: 10px;
}
</style>
