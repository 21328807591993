<template>
  <div class="flex items-center justify-start gap-1">
    <div v-if="isCreateShareSpace" class="w-full">
      <FormInput
          inputName="shareSpace"
          :value="shareSpace"
          :autofocus="true"
          :placeholder="$t('global.inputs.shareSpacesPlaceholder')"
          @handleInputChange="handleInputChange"
          @handleKeyUp="handleKeyUp"
      />
    </div>
    <MainButton
        :name="$t('global.buttons.add')"
        @clickAction="$emit('handleAddShareSpace')"
    />
    <MainButton
        v-if="isCreateShareSpace"
        :name="$t('global.buttons.cancel')"
        bgStyle="light"
        @clickAction="$emit('cancelSharedSpace')"
    />
  </div>
</template>

<script>
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import FormInput from '@/components/Main/Form/FormInput/FormInput';

export default {
  name: 'AddShareSpaceInput',
  components: {
    FormInput,
    MainButton
  },
  props: {
    isCreateShareSpace: Boolean,
    shareSpace: {
      type: String
    }
  },
  methods: {
    handleInputChange(inputName, value) {
      this.$emit('handleInputChange', inputName, value);
    },
    handleKeyUp(event, inputName) {
      this.handleInputChange(inputName, event.target.value);
      event.keyCode === 13 && this.$emit('handleAddShareSpace');
    }
  }
}
</script>

<style scoped>

</style>
