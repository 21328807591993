<template>
  <div class="w-full relative" v-click-outside="closeDropdown">
    <div class="w-full">
      <input
          v-model="searchValue"
          placeholder="Boards..."
          @input="handleSearch"
          type="text"
          autocomplete="off"
          autofocus
          class="w-full h-formInput bg-white relative w-full border border-gray-300 rounded-md pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-gray-300 focus:border-gray-300 focus:ring-opacity-0 sm:text-sm">
    </div>
    <div class="absolute w-full rounded-md bg-white">
      <ul
          class="absolute z-50 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
        <li v-for="(board, index) in boardsList"
            :class="['cursor-pointer select-none relative py-2 pl-8 pr-4', {
                     'text-white bg-primary': selectedBoards.some(selectedBoard => (selectedBoard.id) === (board.id)),
                     'text-gray-900 hover:bg-gray-50': selectedBoards.every(selectedBoard => (selectedBoard.id) !== (board.id))
                }]"
            :key="index"
            @click="handleSelectBoard(board, !!selectedBoards.some(selectedBoard => (selectedBoard.id) === (board.id)))"
        >
          <span class="font-normal block truncate">{{ board.title }}</span>
          <span
              v-if="selectedBoards.some(selectedBoard => (selectedBoard.id) === (board.id))"
              class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   :fill="selectedBoards.some(selectedBoard => (selectedBoard.id) === (board.id)) ? 'white' : 'currentColor'">
                <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import FormInputArrowIcon from '@/components/Icons/FormInputArrowIcon';
import { mapActions } from 'vuex';

export default {
  name: 'BoardsOption',
  components: {
    FormInputArrowIcon
  },
  props: {
    boards: {
      type: Array,
      'default': Array
    },
    space: {
      type: Object
    },
    sharedSpaceBoards: {
      type: Array,
      'default': Array
    }
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      selectedBoards: [],
      boardsList: [],
      searchValue: ''
    }
  },
  watch: {
    sharedSpaceBoards(newValue) {
      this.selectedBoards = this.sharedSpaceBoards;
    }
  },
  mounted() {
    this.boardsList = this.boards;
    this.selectedBoards = this.sharedSpaceBoards;
  },
  methods: {
    ...mapActions({
      handleAddSpaceBoard: 'addSpaceBoard'
    }),
    closeDropdown() {
      // this.$emit('handleCloseAddBoardDropDown');
      // this.$emit('handleCloseBoards');
    },
    handleSelectBoard(item, isSelected) {
      !isSelected ? this.$emit('handleAddSpaceBoard', item) : this.$emit('handleRemoveBoard', item.id);
    },
    handleSearch() {
      this.boardsList = this.boards.filter((board) => {
        if (board.title.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return board;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
