<template>
    <div class="relative inline-block text-left z-40" v-click-outside="closeDropdown">
        <div>
            <button
                @click.prevent="handleOpenDropDown"
                type="button"
                class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
                aria-expanded="true" aria-haspopup="true">
                <DropDownThreeDots/>
            </button>
        </div>
        <div
            v-if="isOpenDropDown"
            class="shadow-lg mx-3 origin-top-right absolute right-1 w-48 mt-1 rounded-md 2xl:hover:z-30 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
            <div @click="handleDeleteItem" class="py-1" role="none">
                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                   role="menuitem">{{ $t('main.delete') }}</a>
            </div>
            <div @click="handleEdit" class="py-1" role="none">
                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                   role="menuitem">{{ $t('main.edit') }}</a>
            </div>
            <div @click="handleOpenAddBoardsDropDown" class="py-1" role="none">
                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                   role="menuitem">{{ $t('main.addBoards') }}</a>
            </div>
            <div class="py-1" role="none">
                <a @click.prevent="handleCopy" href="#" id="copy"
                   class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{{ $t('main.copyLink') }}
                </a>
            </div>
            <div @click="handleOpen" class="py-1" role="none">
                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                   role="menuitem">{{ $t('main.open') }}</a>
            </div>
        </div>
        <div v-if="isOpenBoards"
             class="mx-3 origin-top-right absolute -top-1.5 right-1 w-250p mt-1 rounded-md 2xl:hover:z-30 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
            <BoardsOption
                v-if="boards.length"
                @handleCloseBoards="handleCloseBoards"
                :boards="boards"
                :space="space"
                :sharedSpaceBoards="sharedSpaceBoards"
                @handleCloseAddBoardDropDown="handleCloseAddBoardDropDown"
                @handleRemoveBoard="$emit('handleRemoveBoard', $event)"
                @handleAddSpaceBoard="$emit('handleAddSpaceBoard', $event)"
            />
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import BoardsOption from '@/components/SelectOptions/BoardsOption/BoardsOption';
import DropDownThreeDots from '@/components/Icons/DropDownThreeDots';

export default {
    name: 'EditDeleteShareSpaceDropDown',
    components: {
        DropDownThreeDots,
        BoardsOption
    },
    directives: {
        ClickOutside
    },
    props: {
        space: {
            type: Object,
            required: true
        },
        boards: {
            type: Array,
            'default': Array
        },
        sharedSpaceBoards: {
            type: Array
        }
    },
    computed: {
        location() {
            return `${window.location.origin}/public/space/${this.space.uuid}`;
        }
    },
    data() {
        return {
            isOpenDropDown: false,
            isOpenBoards: false
        };
    },
    methods: {
        handleOpenDropDown() {
            this.isOpenDropDown = !this.isOpenDropDown;
            this.$emit('handleSelectItem', this.space.id);
        },
        closeDropdown() {
            this.$emit('handleSelectItem', '');
            process.nextTick(() => {
                this.isOpenDropDown = false;
            });
        },
        handleDeleteItem() {
            this.closeDropdown();
            this.$emit('openDeleteShareSpaceModal', this.space);
        },
        handleOpenAddBoardsDropDown() {
            this.isOpenDropDown = false;
            this.isOpenBoards = true;
            this.$emit('handleOpenAddBoardsDropDown', true);
        },
        handleCloseAddBoardDropDown() {
            this.$emit('handleOpenAddBoardsDropDown', false);
        },
        handleCloseBoards() {
            this.isOpenBoards = false;
        },
        handleEdit() {
            this.$emit('handleEdit', this.space);
            this.closeDropdown();
        },
        handleCopy() {
            this.toastMessage('Public link copied', 'success');
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.value = this.location;
            input.setAttribute('id', 'copyText');
            const copyText = document.querySelector('#copyText');
            copyText.select();
            document.execCommand('copy');
            document.body.removeChild(input);
            this.closeDropdown();
        },
        handleOpen() {
            window.open(this.location, '_blank');
        },
        toastMessage(message, type) {
            this.$toast.open({ message, type });
        }
    }
};
</script>

<style scoped>

</style>
