<template>
  <div class="flex items-start justify-start gap-2 flex-col">
    <label v-if="label" class="text-sm font-medium text-gray-500">{{ label }}</label>
    <div v-if="disabled" class="ql-snow">
      <div
          ref="textBlock"
          class="ql-editor"
          v-html="editorValue"
      />
    </div>
    <vue-editor v-show="!disabled" v-model="editorValue"
                :editorToolbar="customToolbar"
                :placeholder="placeholder"
                :disabled="disabled"
                :id="inputName"
                @text-change="handleOnInput"
                class="desc_editor resize-none shadow-sm focus:outline-none focus:ring-gray-300 focus:border-gray-300 focus:ring-opacity-0 block w-full sm:text-sm border-gray-300 rounded-md" >
    </vue-editor>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  name: 'FormTextAreaEditor',
  props: {
    inputName: String,
    disabled:{
      type: Boolean,
      'default': false
    },
    label: {
      type: String,
      'default': ''
    },
    placeholder: {
      type: String,
      'default': ''
    },
    rows: {
      type: String,
      'default': '4'
    },
    value:{
      type:  String | Number,
      'default': ''
    }
  },
  components: {
    VueEditor
  },
  data(){
    return {
      editorValue:'',
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        ['link'],
        ['clean'] // remove formatting button
      ]
    }
  },
  mounted() {
    this.editorValue = this.value
    process.nextTick(() => {
      const contentLinks = this.$refs.textBlock?.querySelectorAll('a')
      if (contentLinks) {
        for(const link of contentLinks) {
          link.setAttribute('target', '_blank')
        }
      }
    })
  },
  methods: {
    handleOnInput() {
      this.$emit('handleInputChange', this.inputName, this.editorValue);
    }
  }
}
</script>

<style scoped>
.ql-editor {
  min-height: inherit;
  font-size: inherit;
  line-height: inherit;
  height: inherit;
  outline: inherit;
  overflow-y: inherit;
  padding: inherit;
  tab-size: inherit;
  -moz-tab-size: inherit;
  text-align: inherit;
  white-space: inherit;
  word-wrap: inherit;
}

.desc_editor >>> .ql-snow .ql-tooltip {
  left: auto !important;
}

.ql-editor >>> a {
  cursor: pointer;
}
</style>
