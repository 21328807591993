<template>
  <div>
    <div :class="['flex items-end justify-start assignee_avatar_wrapper', {
         'w-100p': widthFull
       }]">
      <div class="relative" v-if="avatar" v-tooltip.top-center="name">
        <img :src="avatar"  class="max-w-none h-6 w-6 rounded-full ring-2 ring-white mr-1">
      </div>
      <span class="assignee_avatar _abbr h-6 w-6 " v-else-if="name" v-tooltip.top-center="name">
        {{ name | getNameAbbr }}
      </span>
      <span class="flex-shrink-0 text-xs leading-5 font-medium block" v-if="name && !disableName">{{ name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableRowUserAvatar',
  props: {
    name: {
      required: true
    },
    avatar: {
      required: true
    },
    disableName: Boolean,
    widthFull: Boolean
  }
}
</script>

<style lang="scss" scoped>
@import '../../sass/mixins';



.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}
.assignee_avatar_wrapper {
  display: flex;
  align-items: center;
}

.assignee_avatar {
  object-fit: cover;
  border-radius: 50%;
  margin-right: 7px;
  flex: none;

  &._abbr {
    background-color: $border_color;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: $body_dark;
    font-size: 10px;
  }


}
</style>
