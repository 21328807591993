<template>
  <div>
    <DeleteItemModal
      v-if="showModal"
      :title="$t('deleteShareModal.title')"
      :description="$t('deleteShareModal.description')"
      @handleCloseModal="showModal = false"
      @handleConfirm="handleDeleteItem"
    />
    <div class="w-full flex items-start justify-start flex-col gap-1 mt-1">
      <div class="w-full border-b">
        <h3 class="text-sm font-medium text-gray-500 pb-2">{{ $t('main.sharedSpaces') }}</h3>
      </div>
    </div>
    <ShareSpaces
        v-if="sharedSpaces.length"
        :sharedSpaces="sharedSpaces"
        @handleChangeShareSpace="handleChangeShareSpace"
        @openDeleteShareSpaceModal="openDeleteShareSpaceModal"
        :boards="boards"
        :pageUpdateAction="pageUpdateAction"
    />
    <div class="relative border-b pb-2 pt-2">
      <AddNewButton
          v-if="!isCreateShareSpace"
          :name="$t('global.buttons.add_new')"
          @clickAction="handleAddShareSpace"
      />
      <div v-else class="w-full">
        <AddShareSpaceInput
            @handleAddShareSpace="handleAddShareSpace"
            @handleInputChange="handleInputChange"
            @cancelSharedSpace="cancelSharedSpace"
            :isCreateShareSpace="isCreateShareSpace"
            :value="shareSpace"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import EditDeleteShareSpaceDropDown from '@/components/Main/ShareSpace/EditDeleteShareSpaceDropDown/EditDeleteShareSpaceDropDown';
import { mapActions, mapGetters } from 'vuex';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import SharedSpaceItem from '@/components/Main/ShareSpace/SharedSpaceItem/SharedSpaceItem';
import ShareSpaces from '@/components/Main/ShareSpace/ShareSpaces/ShareSpaces';
import AddNewButton from '@/components/Main/Buttons/AddNewButton/AddNewButton';
import AddShareSpaceInput from '@/components/Main/ShareSpace/AddShareSpaceInput/AddShareSpaceInput';

export default {
  name: 'ShareSpace',
  components: {
    AddShareSpaceInput,
    AddNewButton,
    ShareSpaces,
    SharedSpaceItem,
    FormInput,
    EditDeleteShareSpaceDropDown,
    MainButton,
    DeleteItemModal
  },
  directives: {
    ClickOutside
  },
  props: {
    sharedSpaces: {
      type: Array
    },
    pageUpdateAction: {
      type: Boolean,
      'default': true
    }
  },
  data() {
    return {
      isOpen: false,
      shareSpace: '',
      showModal: false,
      isCreateShareSpace: false,
      boards: []
    }
  },
  computed: {
    ...mapGetters(['getBoards'])
  },
  mounted() {
    this.handleGetBoards();
  },
  watch: {
    getBoards(newValue) {
      this.boards = newValue;
    }
  },
  methods: {
    ...mapActions({
      handleGetBoards: 'fetchBoardsOption'
    }),
    closeDropdown() {
      this.isOpen = false;
    },
    openDeleteShareSpaceModal(item) {
      this.showModal = true;
      this.selectedShareSpace = item;
    },
    handleAddShareSpace() {
      this.isCreateShareSpace = true;
      this.shareSpace.length && this.handleEmitAddShareSpace();
      this.shareSpace = '';
    },
    cancelSharedSpace(){
      this.isCreateShareSpace = false;
      this.shareSpace = '';
    },
    handleEmitAddShareSpace() {
      this.$emit('handleAddShareSpaces', this.shareSpace);
      this.isCreateShareSpace = false;
    },
    handleChangeShareSpace(id) {
      const { value } = event.target;
      value.length && this.$emit('updateShareSpace', { value, id });
    },
    handleInputChange(inputName, value) {
      this.shareSpace = value;
    },
    handleDeleteItem() {
      this.showModal = false;
      this.emitDeleteShareSpace();
    },
    emitDeleteShareSpace() {
      this.$emit('deleteShareSpace', this.selectedShareSpace);
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type })
    }
  },
  destroyed() {
    this.$destroy();
  }
}
</script>

<style scoped>

</style>
