<template>
    <div class="flex justify-between">
        <div class="ml-3">
            <div v-if="!editable">{{ item.title }}</div>

            <input
                v-else
                ref="apartment"
                v-model="item.title"
                class="w-full h-auto border-0 overflow-y-hidden outline-none"
                @change="$emit('handleEditItem', item); editable = false;"
            />
        </div>

        <div class="relative flex justify-end items-center" v-click-outside="closeDropdown">
            <button @click="handleOpenDropDown" type="button"
                    class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none">
                <span class="sr-only">{{ $t('apartment.openOptions') }}</span>
                <DropDownThreeDots/>
            </button>
            <div
                v-if="isOpen"
                class="mx-3 origin-top-right absolute top-6 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="project-options-menu-0"
            >
                <div class="py-1" role="none">
                    <a @click.prevent="handleEditItem" href="#"
                       class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                       role="menuitem">
                        {{ $t('global.buttons.edit') }}
                    </a>
                </div>
                <div class="py-1" role="none">
                    <a @click.prevent="handleOpenDeleteItemModal" href="#"
                       class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                       role="menuitem">
                        {{ $t('global.buttons.delete') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import DropDownThreeDots from '@/components/Icons/DropDownThreeDots';
import FormInput from '@/components/Main/Form/FormInput/FormInput';

export default {
    name: 'EditOrDeleteLink',
    directives: {
        ClickOutside
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isOpen: false,
            editable: false
        };
    },
    components: { DropDownThreeDots, FormInput },
    methods: {
        handleOpenDropDown() {
            this.isOpen = !this.isOpen;
            this.$emit('handleSelectItem', this.item.id);
        },
        handleOpenDeleteItemModal() {
            this.$emit('handleOpenDeleteItemModal', this.item.id);
            this.isOpen = false;
        },
        handleEditItem() {
            this.editable = true;
            this.isOpen = false;
            process.nextTick(() => this.$refs.apartment.focus());
        },
        closeDropdown() {
            this.isOpen = false;
            this.$emit('handleSelectItem', '');
        }
    }
};
</script>

<style scoped>

</style>
