<template>
  <div class="mt-8">
    <div v-if="!editable" class="fieldTitle">
      <SelectOptionLabel
        :label="$t('invoiceConnector.fields.assigneeInvoiceConnector')"
        :name="searchValue"
      />
    </div>
    <div v-else class="w-full flex items-start justify-start flex-col gap-2">
      <label class="text-sm font-medium text-gray-500">{{
          $t('invoiceConnector.fields.assigneeInvoiceConnector')
        }}</label>
      <div class="w-full relative" v-click-outside="closeDropdown">
        <div class="w-full">
          <input
            @click="handleOpenDropDown"
            @input="handleSearch"
            v-model="searchValue"
            :disabled="!editable"
            type="text"
            autocomplete="off"
            class="w-full cursor-pointer h-formInput bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-gray-300 focus:border-gray-300 focus:ring-opacity-0 sm:text-sm">
          <FormInputArrowIcon/>
        </div>
        <div class="absolute w-full rounded-md bg-white">
          <ul v-if="isOpen && invoiceConnectorsList.length"
              class="absolute z-50 w-full bg-white max-h-60 rounded-md py-1 ring-opacity-5 overflow-auto focus:outline-none text-sm border border-gray-300">
            <li v-for="invoiceConnector in invoiceConnectorsList"
                :class="['cursor-pointer select-none relative py-2 pl-8 pr-4', {
                         'text-white bg-primary': invoiceConnector.id === selectedInvoiceConnector.id,
                         'text-gray-900 hover:bg-gray-50': invoiceConnector.id !== selectedInvoiceConnector.id
                }]"
                :key="invoiceConnector.id"
                @click="handleSelectInvoiceConnector(invoiceConnector.id)"
            >
              <span class="font-normal block truncate">{{ invoiceConnector.name }}</span>
              <span
                v-if="invoiceConnector.id === selectedInvoiceConnector.id"
                class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   :fill="invoiceConnector.id === selectedInvoiceConnector.id ? 'white' : 'currentColor'">
                <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';
import FormInputArrowIcon from '@/components/Icons/FormInputArrowIcon';
import SelectOptionLabel from '@/components/SelectOptions/SelectOptionLabel';

export default {
  name: 'AssigneeInvoiceConnectors',
  components: {
    SelectOptionLabel,
    FormInputArrowIcon
  },
  directives: {
    ClickOutside
  },
  props: {
    defaultValue: {
      type: String
    },
    editable: {
      type: Boolean,
      'default': true
    }
  },
  data() {
    return {
      isOpen: false,
      searchValue: '',
      invoiceConnectorsArr: [],
      invoiceConnectorsList: [],
      selectedInvoiceConnector: {}
    };
  },
  computed: {
    ...mapGetters(['invoiceConnectors'])
  },
  async mounted() {
    await this.$store.dispatch('getInvoiceConnectors');
  },
  watch: {
    assigneeInvoiceConnectors(newValue) {
      this.invoiceConnectorsArr = newValue;
    },
    invoiceConnectors(newValue) {
      this.invoiceConnectorsList = newValue.invoiceConnectors;
      this.handleSetDefaultValue();
    },
    editable(newValue) {
      !newValue && this.handleSetDefaultValue();
    }
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    handleOpenDropDown() {
      this.isOpen = !this.isOpen;
      this.invoiceConnectorsList = this.invoiceConnectors.invoiceConnectors;
    },
    handleSearch() {
      this.isOpen = true;

      this.invoiceConnectorsList = this.invoiceConnectors.invoiceConnectors.filter((invoiceConnector) => {
        if (invoiceConnector.name.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return invoiceConnector;
        }
      });
    },
    handleSetDefaultValue() {

      if (this.defaultValue) {
        this.selectedInvoiceConnector = this.invoiceConnectorsList.find(
          invoiceConnector => String(invoiceConnector.id) === this.defaultValue);
      }
      this.searchValue = this.selectedInvoiceConnector.name;
    },
    handleSelectInvoiceConnector(id) {
      const selectedInvoiceConnector = this.invoiceConnectors.invoiceConnectors.find(
        invoiceConnector => invoiceConnector.id === id);
      this.searchValue = selectedInvoiceConnector.name;
      this.selectedInvoiceConnector = selectedInvoiceConnector;
      this.isOpen = false;
      this.$emit('handleChangeAssigneeConnectorId', selectedInvoiceConnector.id);
    }
  }
};
</script>

<style scoped>
</style>
