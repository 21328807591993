<template>
  <div>
    <div v-if="sharedSpaces.length" v-click-outside="closeDropdown">
      <ul class="w-full border-b border-gray-200 divide-y divide-gray-200">
        <li
            v-for="space in sharedSpaces"
            :key="space.id"
            class="w-full flex justify-between items-center relative">
          <SharedSpaceItem
              :space="space"
              :pageUpdateAction="pageUpdateAction"
              :selectedItem="String(selectedItem)"
              :editableItem="editableItem"
              :boards="boards"
              @handleEdit="handleEdit"
              @handleSetSelectedItem="handleSetSelectedItem"
              @openDeleteShareSpaceModal="$emit('openDeleteShareSpaceModal', $event)"
              @handleChangeShareSpace="$emit('handleChangeShareSpace', $event)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SharedSpaceItem from '@/components/Main/ShareSpace/SharedSpaceItem/SharedSpaceItem';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'ShareSpaces',
  components: {
    SharedSpaceItem
  },
  directives: {
    ClickOutside
  },
  props: {
    sharedSpaces: {
      type: Array,
      'default': Array
    },
    boards: {
      type: Array,
      'default': Array
    },
    pageUpdateAction: Boolean
  },
  data() {
    return {
      editableItem: '',
      selectedItem: ''
    }
  },
  methods: {
    handleEdit(item) {
      this.editableItem = String(item.id);
    },
    handleSetSelectedItem(spaceId) {
      this.selectedItem = spaceId;
    },
    closeDropdown() {
      this.editableItem = '';
    }
  }
}
</script>

<style scoped>

</style>
